import React from "react"
import EcommerceMonthlyItemManual from "../components/EcommerceMonthlyItemManual"
import Module from "../components/Module"
import { Container } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import VisibilitySensor from "../animations/VisibilitySensor"
import Text from "../components/Text"
import EcommerceMonthlyItemAutomatic from "../components/EcommerceMonthlyItemAutomatic"

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginTop: theme.spacing(2),
    color: "#662482",
  },
  subtitleNext: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  colorAvailable: {
    color: "#662482",
  },
  imageSlider: {
    height: "300px",
  },
  infoBlock: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(4),
    gap: "50px",
  },
  infoImage: {
    width: "100px",
    height: "100px",
    backgroundColor: "#ccc",
  },
  infoText: {
    ...theme.fonts.faces.bodyLhM,
    color: "white",
    "& span": {
      lineHeight: "1.25 !important",
    },
  },
  bulletIcon: {
    width: 24,
    height: 24,
  },
  price: {
    marginBottom: "24px",
    marginTop: "24px",
    fontSize: "1.5rem !important",
  },
  boxOffer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: "#F0F0F0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    borderRadius: "2px",
    marginLeft: "16px",
    marginRight: "16px",
    gap: "8px",
    lineHeight: "normal",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      marginLeft: 0,
      marginRight: 0,
    },
  },
  buttonDisabled: {
    border: "1px solid #a1a1a1",
    backgroundColor: "#d6d6d6",
    color: "#a1a1a1",
    pointerEvents: "none",
    backgroundImage: "none",
    cursor: "not-allowed",
  },
  iconNext: {
    width: "204px",
    padding: theme.spacing(1),
  },
  containerButton: {
    display: "flex",
    justifyContent: "space-between",
    gap: "8px",
  },
  w50: {
    width: "50%",
  },
  w100: {
    width: "100%",
  },
  backgroundContentImg: {
    backgroundColor: "#d9d9d9",
    marginLeft: "auto",
  },
  textRight: {
    textAlign: "right",
  },
  priceStrikethrough: {
    fontSize: "13px !important",
    fontWeight: "400",
    textDecoration: "line-through",
    lineHeight: "15.2px !important",
    margin: "0px !important",
  },
  offerPrice: {
    fontSize: "17px !important",
    fontWeight: "800",
    lineHeight: "15.2px !important",
    color: "#000000",
    margin: "0px !important",
  },
  commercialMessage: {
    fontSize: "18px !important",
    fontWeight: "400",
    lineHeight: "15.2px !important",
    [theme.breakpoints.down("md")]: {
      marginBottom: "15px !important",
      lineHeight: "24px !important",
    },
  },
  containerOfferPrice: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
}))

const EcommerceMonthlyItemModule = props => {
  const {
    title,
    product,
    repo,
    cta,
    sub_title,
    price,
    how_it_works,
    description,
    commercial_manual_message_text,
    commercial_manual_promo_price,
    item,
  } = props

  const classes = useStyles()
  const isManual = how_it_works?.field_value === "manual" ? true : false

  return (
    <VisibilitySensor>
      <Module className="EcommerceMonthlyItem">
        <Container>
          <Text
            variant="titleXL"
            component="h2"
            overline
            weight="bold"
            align="center"
          >
            {title?.field_value}
          </Text>
          {isManual ? (
            <EcommerceMonthlyItemManual
              subTitle={sub_title}
              price={price}
              description={description}
              classes={classes}
              commercialText={commercial_manual_message_text}
              commercialPrice={commercial_manual_promo_price}
              imageGallery={item}
              cta={cta}
              repo={repo}
            ></EcommerceMonthlyItemManual>
          ) : (
            <EcommerceMonthlyItemAutomatic
              classes={classes}
              product={product}
              repo={repo}
              cta={cta}
            />
          )}
        </Container>
      </Module>
    </VisibilitySensor>
  )
}

export default EcommerceMonthlyItemModule
