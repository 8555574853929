import React, { useEffect, useRef, useState } from "react"
import Module from "./Module"
import { Box, Container, Grid } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import VisibilitySensor from "../animations/VisibilitySensor"
import CtaButton from "./CtaButton"
import Text from "./Text"
import clsx from "clsx"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination, Thumbs, Controller } from "swiper"
import "swiper/swiper-bundle.css"
import { useTranslation } from "react-i18next"
import { GTMEvent, GTMEventAddToCart } from "../utils/gtm"
import FluidImage from "./FluidImage"
import Icon from "./Icon"

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginTop: theme.spacing(2),
    color: "#662482",
  },
  subtitleNext: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  colorAvailable: {
    color: "#662482",
  },
  price: {
    marginBottom: "24px",
    marginTop: "24px",
    fontSize: "1.5rem !important",
  },
  boxOffer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: "#F0F0F0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    borderRadius: "2px",
    marginLeft: "16px",
    marginRight: "16px",
    gap: "8px",
    lineHeight: "normal",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  iconNext: {
    width: "204px",
    padding: theme.spacing(1),
  },
  w50: {
    width: "50%",
  },
  priceStrikethrough: {
    fontSize: "13px !important",
    fontWeight: "400",
    textDecoration: "line-through",
    lineHeight: "15.2px !important",
    margin: "0px !important",
  },
  offerPrice: {
    fontSize: "17px !important",
    fontWeight: "800",
    lineHeight: "15.2px !important",
    color: "#000000",
    margin: "0px !important",
  },
  commercialMessage: {
    fontSize: "18px !important",
    fontWeight: "400",
    lineHeight: "15.2px !important",
    [theme.breakpoints.down("md")]: {
      marginBottom: "15px !important",
      lineHeight: "24px !important",
    },
  },
  containerOfferPrice: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  miniFigure: {
    height: "70px",
    width: "70px",
    margin: "auto",
    "&:hover": {
      cursor: "pointer",
    },
    "& img": {
      objectFit: "contain",
      objectPosition: "center",
      height: "100%",
      width: "100%",
    },
  },
  containerGiftSwiper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  arrow: {
    backgroundColor: "transparent",
    borderColor: "transparent",
    cursor: "pointer",
  },
  icon: {
    width: 24,
    height: 24,
  },
}))

SwiperCore.use([Navigation, Pagination, Thumbs, Controller])

const EcommerceMoreInfo = props => {
  const { title, product, distributionType, indexPicture, repo, cta, cart } =
    props
  const classes = useStyles()
  const [currentSelect, setCurrentSelect] = React.useState(indexPicture || 0)
  const [isActive, setIsActive] = React.useState(false)

  const [prodottiSwiper, setProdottiSwiper] = useState(null)
  const [controlSwiper, setControlSwiper] = useState(null)
  const [thumbsSwipers, setThumbsSwipers] = useState([])
  const thumbsRef = useRef([])
  const containerRef = useRef(null)
  const selectedRef = useRef(null)
  const [activeProductIndex, setActiveProductIndex] = useState(
    indexPicture || 0
  )

  const { t } = useTranslation()
  const sku =
    product?.field_value[currentSelect]?.figure_ecommerce_code ||
    product?.field_value[indexPicture]?.field_value[
      `figure_${distributionType}_code`
    ]

  const handleControlClick = index => {
    if (index >= 0 && index <= product?.field_value.length - 1) {
      prodottiSwiper?.slideTo(index)
      setActiveProductIndex(index)
    }
  }

  useEffect(() => {
    if (cart) {
      cart.reloadListeners()
    }

    const isEcommerceItemActive = activationDate => {
      const inputDate = new Date(activationDate)
      const today = new Date()
      return inputDate.getTime() < today.getTime()
    }

    const activationDate =
      product?.field_value[currentSelect]?.figure_ecommerce_date?.field_value ||
      product?.field_value[currentSelect]?.field_value[
        `figure_${distributionType}_date`
      ]?.field_value
    setIsActive(isEcommerceItemActive(activationDate))
  }, [])

  useEffect(() => {
    if (selectedRef.current && containerRef.current) {
      selectedRef.current.scrollIntoView({
        boundary: "carousel",
        behavior: "smooth",
        block: "nearest",
        inline: activeProductIndex === 0 ? "start" : "center",
      })
    }
  }, [activeProductIndex])

  function formatPrice(price) {
    if (typeof price === "string") {
      return price.replace(",", ".")
    }
    return price
  }

  function formattedDate(dateNextPack) {
    const date = new Date(dateNextPack)
    const options = { day: "numeric", month: "short" }
    const formattedDate = date.toLocaleDateString(
      repo.seo.siteLanguage,
      options
    )
    return formattedDate
  }

  return (
    <VisibilitySensor>
      <Module className={clsx(`EcommerceMonthlyItem EcommerceMoreInfo`)}>
        <Container>
          <Text
            variant="titleXL"
            component="h2"
            overline
            weight="bold"
            align="center"
          >
            {title?.field_value}
          </Text>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#f0f0f0",
              gap: "10px",
            }}
          >
            <button
              disabled={activeProductIndex === 0}
              className={clsx(classes.arrow, "image-ecommerce__custom_prev")}
              onClick={() => handleControlClick(activeProductIndex - 1)}
            >
              <Icon className={classes.icon} name="dropdown-left-yellow" />
            </button>
            <div
              style={{
                overflow: "hidden",
                display: "flex",
                flexWrap: "nowrap",
                justifyContent: "start",
                alignItems: "center",
                whiteSpace: "nowrap",
                scrollBehavior: "smooth",
                gap: "10px",
              }}
              ref={containerRef}
            >
              {product.field_value.map((prod, productIndex) => (
                <div
                  ref={productIndex === activeProductIndex ? selectedRef : null}
                  onClick={() => handleControlClick(productIndex)}
                  style={{
                    border:
                      productIndex === activeProductIndex
                        ? "1px solid purple"
                        : "1px solid transparent",
                    filter:
                      productIndex === activeProductIndex
                        ? "invert(51%) sepia(41%) saturate(2098%) hue-rotate(10deg) brightness(106%) contrast(101%)"
                        : "none",
                    flexShrink: 0,
                  }}
                >
                  <FluidImage
                    className={`${classes.miniFigure}`}
                    {...prod.field_value.figure_image.field_value}
                  />
                </div>
              ))}
            </div>
            <button
              disabled={activeProductIndex >= product.field_value.length - 1}
              className={clsx(classes.arrow, "image-ecommerce__custom_next")}
              onClick={() => handleControlClick(activeProductIndex + 1)}
            >
              <Icon className={classes.icon} name="dropdown-right-yellow" />
            </button>
          </div>
          <Swiper
            slidesPerView={1}
            onSwiper={setProdottiSwiper}
            onSlideChange={swipe => handleControlClick(swipe.activeIndex)}
            controller={{ control: controlSwiper }}
            initialSlide={activeProductIndex}
          >
            {product.field_value.map((prod, productIndex) => {
              let arrayImg = []
              let coverImg = prod?.field_value[
                `figure_${distributionType}_image_override`
              ]
                ? prod?.field_value[`figure_${distributionType}_image_override`]
                    .field_value
                : prod?.field_value?.figure_image?.field_value
              arrayImg = [coverImg]
              prod?.field_value?.figure_gallery?.field_value?.forEach(figure =>
                arrayImg.push(
                  figure?.field_value?.figure_gallery_image?.field_value
                )
              )
              return (
                <SwiperSlide key={`prodotto-${productIndex}`}>
                  <Grid
                    spacing={4}
                    className={clsx("container__main", "mt-10")}
                  >
                    <Grid className={clsx("section__slider")}>
                      <Swiper
                        className="gallery-container swiper__core"
                        id={`galleria-${productIndex}`}
                        thumbs={{
                          swiper: thumbsSwipers[productIndex],
                        }}
                        navigation={arrayImg.length > 1 && productIndex === 0}
                        loop={arrayImg.length > 1 && productIndex === 0}
                        loopedSlides={arrayImg.length}
                        slidesPerView={1}
                        spaceBetween={15}
                        style={{
                          "--swiper-navigation-color": "#f29100",
                          "--swiper-pagination-color": "#f29100",
                        }}
                      >
                        {(arrayImg.length > 1 && productIndex === 0
                          ? arrayImg
                          : [arrayImg[0]]
                        ).map((el, index) => (
                          <SwiperSlide key={`gallery-${productIndex}-${index}`}>
                            <div
                              className="background-cover"
                              style={{
                                background: `url(${el?.src ?? ""})`,
                                color: `green`,
                              }}
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <Swiper
                        className={`thumb-container swiper__thumb`}
                        onSwiper={swiper => {
                          thumbsRef.current[productIndex] = swiper
                          const newThumbsSwipers = [...thumbsRef.current]
                          setThumbsSwipers(newThumbsSwipers)
                        }}
                        spaceBetween={15}
                        slidesPerView={4}
                        loop={false}
                        watchSlidesProgress={true}
                        freeMode={true}
                      >
                        {(arrayImg.length > 1 && productIndex === 0
                          ? arrayImg
                          : [arrayImg[0]]
                        ).map((el, index) => (
                          <SwiperSlide key={`thumb-${productIndex}-${index}`}>
                            <div
                              className="background-cover"
                              style={{
                                background: `url(${el?.src ?? ""})`,
                                color: `#ff0000`,
                              }}
                              onClick={() =>
                                GTMEvent(
                                  repo,
                                  "click_image",
                                  "slider_image-module"
                                )
                              }
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </Grid>
                    <Grid className={clsx("section__title")}>
                      <Text variant="titleXXL" component="h2" weight="bold">
                        {prod?.field_value?.figure_title_html?.field_value}
                      </Text>
                    </Grid>
                    <Grid className={clsx("section__text")}>
                      <p className="mb-5">
                        <Text
                          variant="titleL"
                          weight="bold"
                          tag="span"
                          className={classes.price}
                        >
                          {formatPrice(
                            prod?.field_value[
                              `figure_${distributionType}_price`
                            ]?.field_value
                          )}
                        </Text>

                        <Text
                          className="boxDescription"
                          dangerouslySetInnerHTML={{
                            __html:
                              prod?.field_value?.figure_description
                                ?.field_value,
                          }}
                        ></Text>
                      </p>
                      {prod?.field_value[
                        `figure_${distributionType}_commercial_message_text`
                      ]?.field_value && (
                        <Box className={classes.boxOffer}>
                          <Text
                            variant="titleL"
                            className={classes.commercialMessage}
                            dangerouslySetInnerHTML={{
                              __html:
                                prod?.field_value[
                                  `figure_${distributionType}_commercial_message_text`
                                ]?.field_value,
                            }}
                          />
                          <div className={classes.containerOfferPrice}>
                            <Text
                              variant="titleL"
                              className={classes.offerPrice}
                              dangerouslySetInnerHTML={{
                                __html:
                                  prod?.field_value[
                                    `figure_${distributionType}_commercial_promo_price`
                                  ]?.field_value,
                              }}
                            />

                            <Text
                              variant="titleL"
                              className={classes.priceStrikethrough}
                            >
                              {formatPrice(
                                prod?.field_value[
                                  `figure_${distributionType}_price`
                                ]?.field_value
                              )}
                            </Text>
                          </div>
                        </Box>
                      )}
                      <Box className={clsx("buttons-container")}>
                        <div class="buttonCartContainer container-button-add-to-cart button-action">
                          {isActive ? (
                            <button
                              data-sku={sku?.field_value}
                              className={clsx(
                                "add-cart-item-trigger button-add-to-cart"
                              )}
                              disabled
                            >
                              <Text
                                tag="span"
                                className="container-text-add-to-cart"
                                onClick={() => {
                                  GTMEvent(
                                    repo,
                                    "starship_ofthemonth_module",
                                    "buy_now"
                                  )
                                  GTMEventAddToCart(
                                    repo ?? "",
                                    sku ?? "",
                                    prod?.field_value[
                                      `figure_${distributionType}_price`
                                    ] || "",
                                    prod?.field_value[
                                      `figure_${distributionType}_currency_code`
                                    ] || ""
                                  )
                                }}
                              >
                                {
                                  prod?.field_value?.figure_cta_addtocart
                                    ?.field_value
                                }
                              </Text>
                            </button>
                          ) : (
                            <button
                              data-sku={sku?.field_value}
                              className={clsx("button-add-to-cart")}
                              disabled
                            >
                              {
                                prod?.field_value?.figure_cta_addtocart
                                  ?.field_value
                              }
                            </button>
                          )}
                        </div>
                        {cta?.field_value?.product_code && (
                          <CtaButton
                            className={clsx(
                              "button-subscribe",
                              "button-action",
                              classes.w50
                            )}
                            {...cta.field_value}
                            size="large"
                            trackingObj={repo}
                            trackingAction="starship_ofthemonth_module"
                            trackingLabel={"Subscribe"}
                          />
                        )}
                      </Box>
                      {prod?.field_value?.next_item && (
                        <Box className="next-ship">
                          <Box className="text">
                            <Text>{t("ecommerce.comingNextMonth")}</Text>
                            <Text
                              variant="titleL"
                              weight="bold"
                              tag="span"
                              className={classes.subtitleNext}
                            >
                              {
                                prod?.field_value?.next_item?.figure_title_html
                                  ?.field_value
                              }
                            </Text>
                            <Text className={classes.colorAvailable}>
                              {t("ecommerce.labelAvailableDate") +
                                formattedDate(
                                  prod?.field_value?.next_item[
                                    `figure_${distributionType}_date`
                                  ]?.field_value
                                )}
                            </Text>
                          </Box>
                          <div className="image">
                            <img
                              className={classes.iconNext}
                              src={
                                prod?.field_value?.next_item?.figure_image
                                  ?.field_value?.src
                              }
                            />
                          </div>
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Container>
      </Module>
    </VisibilitySensor>
  )
}

export default EcommerceMoreInfo
