import React, { useEffect, useState } from "react"
import clsx from "clsx"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
  Grid,
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  useMediaQuery,
} from "@material-ui/core"
import Slide from "@material-ui/core/Slide"

import Text from "./Text"
import Icon from "./Icon"
import FluidImage from "./FluidImage"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination, Thumbs } from "swiper"
import "swiper/swiper-bundle.css"

const useStyles = makeStyles(theme => ({
  giftOverlay: {
    "& .swiper-slide-thumb-active": {
      filter:
        "invert(51%) sepia(41%) saturate(2098%) hue-rotate(10deg) brightness(106%) contrast(101%)",
    },
  },
  appBar: {
    position: "relative",
    padding: "0 8px 0 8px !important",
    background: "white",
    borderBottom: `1px solid ${theme.customPalette.greyClear}`,
    [theme.breakpoints.down("sm")]: {
      borderTop: `27px solid ${theme.customPalette.greyClear}`,
    },
  },
  appBarSticky: {
    top: 0,
    left: "auto",
    right: 0,
    width: "100%",
    zIndex: 1100,
    position: "sticky",
    padding: "0 8px 0 8px !important",
    background: "white",
    borderBottom: `1px solid ${theme.customPalette.greyClear}`,
    [theme.breakpoints.down("sm")]: {
      borderTop: `27px solid ${theme.customPalette.greyClear}`,
    },
  },
  body: {
    padding: theme.spacing(0, 4, 4, 4),
  },
  icon: {
    height: 24,
    width: 24,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  image: {
    height: 380,
    width: 400,
    display: "block",
    [theme.breakpoints.down("xs")]: {
      width: 300,
      height: 340,
    },
  },
  name: {
    paddingBottom: theme.spacing(2),
  },
  descriptio: {},
  miniFigure: {
    height: "70px",
    width: "70px",
    margin: "auto",
    "&:hover": {
      cursor: "pointer",
    },
    "& img": {
      objectFit: "contain",
      objectPosition: "center",
      height: "100%",
      width: "100%",
    },
  },
  containerGiftSwiper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  containerImgSwiper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  backgroundColorSwiper: {
    backgroundColor: "#f0f0f0",
  },
  arrow: {
    backgroundColor: "transparent",
    borderColor: "transparent",
    cursor: "pointer",
  },
  icon: {
    width: 24,
    height: 24,
  },
  mySwiper2: {
    paddingBottom: "16px !important",
    paddingTop: "16px !important",
  },
  containerTitle: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(4),
    },
  },
  disableArrow: {
    opacity: "0",
    cursor: "none",
    pointerEvents: "none",
  },
}))

SwiperCore.use([Navigation, Pagination])
SwiperCore.use([Thumbs])

const swiperResponsive = {
  768: {
    slidesPerView: 1,
    spaceBetween: 14,
  },
  1024: {
    slidesPerView: 4,
    spaceBetween: 100,
  },
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const GiftOverlay = ({
  className,
  title,
  gifts,
  open,
  onClose,
  initialPos,
  stickyHeader,
  tracking,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [indexSwiper, setIndexSwiper] = useState(initialPos)
  const giftsLength = gifts.length

  return (
    <Dialog
      className={clsx(className, classes.giftOverlay)}
      fullScreen={isMobile}
      fullWidth
      keepMounted
      maxWidth="lg"
      PaperProps={{
        square: true,
      }}
      open={open}
      TransitionComponent={Transition}
    >
      <AppBar
        className={!!stickyHeader ? classes.appBarSticky : classes.appBar}
        elevation={0}
      >
        <Toolbar disableGutters>
          <Text variant="title" color="textPrimary" className={classes.title}>
            {title}
          </Text>
          <IconButton
            edge="start"
            size="small"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Icon className={classes.icon} name="close" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Grid xs={12} md={12} className={clsx("section__slider column")}>
        <div
          className={clsx(
            classes.containerGiftSwiper,
            classes.backgroundColorSwiper
          )}
        >
          <button
            onClick={() => indexSwiper > 0 && setIndexSwiper(indexSwiper - 1)}
            className={clsx(
              indexSwiper === 0 && classes.disableArrow,
              classes.arrow,
              "image-gift__custom_prev"
            )}
          >
            <Icon className={classes.icon} name="dropdown-left-yellow"></Icon>
          </button>
          <Swiper
            onSwiper={setThumbsSwiper}
            breakpoints={swiperResponsive}
            className="mySwiper"
            allowTouchMove
            navigation={{
              nextEl: ".image-gift__custom_next",
              prevEl: ".image-gift__custom_prev",
            }}
            initialSlide={initialPos}
          >
            {gifts.map(gift => {
              return (
                <SwiperSlide>
                  <FluidImage
                    className={classes.miniFigure}
                    {...gift.image}
                  ></FluidImage>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <button
            onClick={() =>
              indexSwiper < giftsLength && setIndexSwiper(indexSwiper + 1)
            }
            className={clsx(
              indexSwiper === giftsLength - 1 && classes.disableArrow,
              classes.arrow,
              "image-gift__custom_next"
            )}
          >
            <Icon className={classes.icon} name="dropdown-right-yellow"></Icon>
          </button>
        </div>
        <Swiper
          navigation={{
            nextEl: ".image-gift__custom_next",
            prevEl: ".image-gift__custom_prev",
          }}
          thumbs={{ swiper: thumbsSwiper }}
          className={classes.mySwiper2}
          allowTouchMove={false}
          initialSlide={initialPos}
        >
          {gifts.map(gift => {
            return (
              <SwiperSlide>
                <div className={classes.containerImgSwiper}>
                  <FluidImage
                    className={classes.image}
                    {...gift.image}
                  ></FluidImage>
                  <Grid className={classes.containerTitle} md={6}>
                    <Text
                      className={classes.name}
                      component="div"
                      align="center"
                      variant="titleXXL"
                    >
                      {gift.name}
                    </Text>
                    <Text
                      className={classes.description}
                      align="center"
                      variant="bodyM"
                      weight="medium"
                    >
                      {gift.description}
                    </Text>
                  </Grid>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </Grid>
    </Dialog>
  )
}

export default GiftOverlay
