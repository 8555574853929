import React, { useState } from "react"
import { Box, Grid } from "@material-ui/core"
import CtaButton from "../components/CtaButton"
import Text from "../components/Text"
import clsx from "clsx"
import "../style/EcommerceMonthlyItemModule.css"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination, FreeMode, Thumbs } from "swiper"
import "swiper/swiper-bundle.css"
import { GTMEvent } from "../utils/gtm"

SwiperCore.use([Navigation, Pagination])

SwiperCore.use([Thumbs])

const EcommerceMonthlyItemManual = ({
  subTitle,
  price,
  description,
  classes,
  commercialText,
  commercialPrice,
  imageGallery,
  cta,
  repo,
}) => {
  function formatPrice(price) {
    if (typeof price === "string") {
      return price.replace(",", ".")
    }
    return price
  }
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  return (
    <Grid spacing={4} className={clsx("container__main", "mt-10")}>
      <Grid className="section__title">
        <Text variant="titleXXL" component="h2" weight="bold">
          {subTitle?.field_value}
        </Text>
      </Grid>
      <Grid className={clsx("section__slider")}>
        <Swiper
          style={{
            "--swiper-navigation-color": "#f29100",
            "--swiper-pagination-color": "#f29100",
          }}
          spaceBetween={15}
          navigation={true}
          loop={true}
          thumbs={{ swiper: thumbsSwiper }}
          loopedSlides={imageGallery?.field_value?.length}
          modules={[FreeMode, Navigation, Thumbs]}
          className={`${
            imageGallery?.field_value?.length > 1 ? "d-block" : "d-none"
          } swiper__core`}
          onSwiper={swiper => {
            setThumbsSwiper(swiper)
          }}
        >
          {imageGallery?.field_value?.map(imgFirstSlider => {
            return (
              <SwiperSlide>
                <div
                  className="background-cover"
                  style={{
                    background: `url(${
                      imgFirstSlider?.field_value?.image?.field_value?.src || ""
                    })`,
                    color: `green`,
                  }}
                ></div>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <Swiper
          loop={true}
          onSwiper={setThumbsSwiper}
          spaceBetween={15}
          slidesPerView={4}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className={`${
            imageGallery?.field_value?.length > 1 ? "d-block" : "d-none"
          } swiper__thumb`}
        >
          {imageGallery?.field_value.map(imgFirstSlider => {
            return (
              <SwiperSlide>
                <div
                  className="background-cover"
                  style={{
                    background: `url(${
                      imgFirstSlider?.field_value?.image?.field_value?.src || ""
                    })`,
                    color: `#ff0000`,
                  }}
                  onClick={() =>
                    GTMEvent(repo, "click_image", "slider_image-module")
                  }
                ></div>
              </SwiperSlide>
            )
          })}
        </Swiper>
        <Swiper
          spaceBetween={15}
          thumbs={{ swiper: thumbsSwiper }}
          className={`${
            imageGallery?.field_value?.length <= 1 ? "d-block" : "d-none"
          } swiper__core`}
        >
          <SwiperSlide>
            <div
              className="background-cover"
              style={{
                background: `url(${
                  imageGallery?.field_value[0]?.field_value?.image?.field_value
                    ?.src || ""
                })`,
                color: `green`,
              }}
            ></div>
          </SwiperSlide>
        </Swiper>
      </Grid>
      <Grid className={clsx("section__text")}>
        <p className="mb-5">
          <Text
            variant="titleL"
            weight="bold"
            tag="span"
            className={classes.price}
          >
            {formatPrice(price?.field_value)}
          </Text>

          <Text
            className="boxDescription"
            dangerouslySetInnerHTML={{
              __html: description?.field_value,
            }}
          ></Text>
        </p>
        {commercialText?.field_value && (
          <Box className={classes.boxOffer}>
            <Text
              variant="titleL"
              className={classes.commercialMessage}
              dangerouslySetInnerHTML={{
                __html: commercialText?.field_value,
              }}
            />
            <div className={classes.containerOfferPrice}>
              <Text
                variant="titleL"
                className={classes.offerPrice}
                dangerouslySetInnerHTML={{
                  __html: commercialPrice?.field_value,
                }}
              />

              <Text variant="titleL" className={classes.priceStrikethrough}>
                {formatPrice(price?.field_value)}
              </Text>
            </div>
          </Box>
        )}
        <Box className={clsx("buttons-container")}>
          {cta?.field_value?.product_code && (
            <CtaButton
              className={clsx("button-subscribe", "button-action", classes.w50)}
              {...cta.field_value}
              size="large"
              trackingObj={repo}
              trackingAction="starship_ofthemonth_module"
              trackingLabel={"Subscribe"}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default EcommerceMonthlyItemManual
