import React, { useEffect, useState } from "react"
import { Box, Grid, MenuItem } from "@material-ui/core"
import CtaButton from "../components/CtaButton"
import Text from "../components/Text"
import Select from "../components/Select"
import clsx from "clsx"
import "../style/EcommerceMonthlyItemModule.css"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Navigation, Pagination, FreeMode, Thumbs } from "swiper"
import "swiper/swiper-bundle.css"
import { useTranslation } from "react-i18next"
import { GTMEvent, GTMEventAddToCart } from "../utils/gtm"

SwiperCore.use([Navigation, Pagination])

SwiperCore.use([Thumbs])

const EcommerceMonthlyItemAutomatic = ({ classes, product, repo, cta }) => {
  const [currentSelect, setCurrentSelect] = React.useState(0)
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [figuresItem, setFiguresItem] = React.useState([])
  const [dateFormatted, setDateFormatted] = React.useState("")
  const [isActive, setIsActive] = React.useState(false)
  const sku = product?.field_value[currentSelect]?.figure_ecommerce_code
  const nextPack = currentSelect + 1

  const { t } = useTranslation()

  useEffect(() => {
    let array = []
    let coverImg = product?.field_value[currentSelect]
      ?.ecommerce_monthly_item_manual_image_override
      ? product.field_value[currentSelect]
          .ecommerce_monthly_item_manual_image_override?.field_value
      : product?.field_value[currentSelect]?.figure_image?.field_value
    array = [coverImg]
    product?.field_value[currentSelect]?.figure_gallery?.field_value?.forEach(
      figure =>
        array.push(figure?.field_value?.figure_gallery_image?.field_value)
    )

    setFiguresItem(array)

    const dateNextPack =
      product?.field_value[nextPack]?.figure_ecommerce_date?.field_value
    const date = new Date(dateNextPack)
    const options = { day: "numeric", month: "short" }
    const formattedDate = date.toLocaleDateString(
      repo?.seo?.siteLanguage,
      options
    )
    setDateFormatted(formattedDate)

    const isEcommerceItemActive = activationDate => {
      const inputDate = new Date(activationDate)
      const today = new Date()
      return inputDate.getTime() < today.getTime()
    }

    const activationDate =
      product?.field_value[currentSelect]?.figure_ecommerce_date?.field_value
    setIsActive(isEcommerceItemActive(activationDate))
  }, [])

  const handleProduct = index => {
    let array = []
    let coverImg = product?.field_value[index]
      ?.ecommerce_monthly_item_manual_image_override
      ? product.field_value[index].ecommerce_monthly_item_manual_image_override
          ?.field_value
      : product?.field_value[index]?.figure_image?.field_value
    array = [coverImg]
    product?.field_value[index]?.figure_gallery?.field_value?.forEach(figure =>
      array.push(figure?.field_value?.figure_gallery_image?.field_value)
    )

    setFiguresItem(array)

    const dateNextPack =
      product?.field_value[index + 1]?.figure_ecommerce_date?.field_value
    const date = new Date(dateNextPack)
    const options = { day: "numeric", month: "short" }
    const formattedDate = date.toLocaleDateString(
      repo.seo.siteLanguage,
      options
    )
    setDateFormatted(formattedDate)
  }

  function formatPrice(price) {
    if (typeof price === "string") {
      return price.replace(",", ".")
    }
    return price
  }

  return (
    <>
      {process.env.NODE_ENV === "development" &&
        product?.field_value[currentSelect]?.figure_ecommerce_date
          ?.field_value && (
          <Box px={2} my={5} mx={"auto"} className={classes.select}>
            <Select
              value={currentSelect}
              onChange={select => {
                setCurrentSelect(select?.target?.value),
                  handleProduct(select?.target?.value)
              }}
            >
              {product.field_value.map((item, index) => {
                return (
                  <MenuItem key={index} value={index}>
                    {item?.figure_ecommerce_title?.field_value +
                      " - " +
                      item?.figure_ecommerce_date?.field_value}
                  </MenuItem>
                )
              })}
            </Select>
          </Box>
        )}
      <Grid spacing={4} className={clsx("container__main", "mt-10")}>
        <Grid className="section__title">
          <Text variant="titleXXL" component="h2" weight="bold">
            {
              product?.field_value[currentSelect]?.figure_ecommerce_title_html
                ?.field_value
            }
          </Text>
        </Grid>
        <Grid className={clsx("section__slider")}>
          <Swiper
            style={{
              "--swiper-navigation-color": "#f29100",
              "--swiper-pagination-color": "#f29100",
            }}
            spaceBetween={15}
            navigation={true}
            loop={true}
            thumbs={{ swiper: thumbsSwiper }}
            loopedSlides={figuresItem?.length}
            modules={[FreeMode, Navigation, Thumbs]}
            className={`${
              figuresItem?.length > 1 ? "d-block" : "d-none"
            } swiper__core`}
            onSwiper={swiper => {
              setThumbsSwiper(swiper)
            }}
          >
            {figuresItem?.map(imgFirstSlider => {
              return (
                <SwiperSlide>
                  <div
                    className="background-cover"
                    style={{
                      background: `url(${imgFirstSlider?.src || ""})`,
                      color: `green`,
                    }}
                  ></div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <Swiper
            loop={true}
            onSwiper={setThumbsSwiper}
            spaceBetween={15}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className={`${
              figuresItem?.length > 1 ? "d-block" : "d-none"
            } swiper__thumb`}
          >
            {figuresItem.map(imgFirstSlider => {
              return (
                <SwiperSlide>
                  <div
                    className="background-cover"
                    style={{
                      background: `url(${imgFirstSlider?.src || ""})`,
                      color: `#ff0000`,
                    }}
                    onClick={() =>
                      GTMEvent(repo, "click_image", "slider_image-module")
                    }
                  ></div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          <Swiper
            spaceBetween={15}
            thumbs={{ swiper: thumbsSwiper }}
            className={`${
              figuresItem?.length <= 1 ? "d-block" : "d-none"
            } swiper__core`}
          >
            <SwiperSlide>
              <div
                className="background-cover"
                style={{
                  background: `url(${figuresItem[0]?.src || ""})`,
                  color: `green`,
                }}
              ></div>
            </SwiperSlide>
          </Swiper>
        </Grid>
        <Grid className={clsx("section__text")}>
          <p className="mb-5">
            <Text
              variant="titleL"
              weight="bold"
              tag="span"
              className={classes.price}
            >
              {formatPrice(
                product?.field_value[currentSelect]?.figure_ecommerce_price
                  ?.field_value
              )}
            </Text>

            <Text
              className="boxDescription"
              dangerouslySetInnerHTML={{
                __html:
                  product?.field_value[currentSelect]
                    ?.figure_ecommerce_description?.field_value,
              }}
            ></Text>
          </p>
          {product?.field_value[currentSelect]?.commercial_message_text
            ?.field_value && (
            <Box className={classes.boxOffer}>
              <Text
                variant="titleL"
                className={classes.commercialMessage}
                dangerouslySetInnerHTML={{
                  __html:
                    product?.field_value[currentSelect]?.commercial_message_text
                      ?.field_value,
                }}
              />
              <div className={classes.containerOfferPrice}>
                <Text
                  variant="titleL"
                  className={classes.offerPrice}
                  dangerouslySetInnerHTML={{
                    __html:
                      product?.field_value[currentSelect]
                        ?.commercial_promo_price?.field_value,
                  }}
                />

                <Text variant="titleL" className={classes.priceStrikethrough}>
                  {formatPrice(
                    product?.field_value[currentSelect]?.figure_ecommerce_price
                      ?.field_value
                  )}
                </Text>
              </div>
            </Box>
          )}
          <Box className={clsx("buttons-container")}>
            <div class="buttonCartContainer container-button-add-to-cart button-action">
              {isActive ? (
                <button
                  data-sku={sku?.field_value}
                  className={clsx("add-cart-item-trigger button-add-to-cart")}
                  disabled
                >
                  <Text
                    tag="span"
                    className="container-text-add-to-cart"
                    onClick={() => {
                      GTMEvent(repo, "starship_ofthemonth_module", "buy_now")
                      GTMEventAddToCart(
                        repo ?? "",
                        sku ?? "",
                        product?.field_value[currentSelect]
                          ?.figure_ecommerce_price || "",
                        product?.field_value[currentSelect]?.currency_code || ""
                      )
                    }}
                  >
                    {
                      product?.field_value[currentSelect]?.figure_cta_addtocart
                        ?.field_value
                    }
                  </Text>
                </button>
              ) : (
                <button
                  data-sku={sku?.field_value}
                  className={clsx("button-add-to-cart")}
                  disabled
                >
                  {
                    product?.field_value[currentSelect]?.figure_cta_addtocart
                      ?.field_value
                  }
                </button>
              )}
            </div>
            {cta?.field_value?.product_code && (
              <CtaButton
                className={clsx(
                  "button-subscribe",
                  "button-action",
                  classes.w50
                )}
                {...cta.field_value}
                size="large"
                trackingObj={repo}
                trackingAction="starship_ofthemonth_module"
                trackingLabel={"Subscribe"}
              />
            )}
          </Box>
          {product?.field_value[nextPack]?.figure_ecommerce_title
            ?.field_value && (
            <Box className="next-ship">
              <Box className="text">
                <Text>{t("ecommerce.comingNextMonth")}</Text>
                <Text
                  variant="titleL"
                  weight="bold"
                  tag="span"
                  className={classes.subtitleNext}
                >
                  {
                    product?.field_value[nextPack]?.figure_ecommerce_title_html
                      ?.field_value
                  }
                </Text>
                <Text className={classes.colorAvailable}>
                  {t("ecommerce.labelAvailableDate") + dateFormatted}
                </Text>
              </Box>
              <div className="image">
                <img
                  className={classes.iconNext}
                  src={
                    product?.field_value[nextPack]?.figure_image?.field_value
                      ?.src
                  }
                ></img>
              </div>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default EcommerceMonthlyItemAutomatic
