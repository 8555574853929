import React, { useState } from "react"
import clsx from "clsx"
import txt from "@src/utils/txt"
import { makeStyles } from "@material-ui/core/styles"
import { Drawer, Collapse, Box, RadioGroup, Divider } from "@material-ui/core"
import { Card, CardHeader, CardContent } from "@material-ui/core"
import { Radio, IconButton } from "@material-ui/core"
import { MenuItem } from "@material-ui/core"
import { Chip } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import Text from "./Text"
import Select from "./Select"
import Button from "./Button"
import Icon from "./Icon"
import FluidImage from "./FluidImage"
import Stamp from "./Stamp"
import { useTranslation } from "react-i18next"
import SubscribeFormNotify from "./SubscribeFormNotify"
import { detectAlpha2Country } from "../utils/country"

const drawerWidth = "50%"

const useStyles = makeStyles(theme => ({
  drawer: {
    position: "absolute",
    zIndex: "1400",
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 21px",
    borderBottom: "1px solid #f0f0f0",
  },
  drawerContent: {
    padding: theme.spacing(0, 3),
    height: "100%",
    overflowY: "auto",
  },
  drawerActions: {
    padding: theme.spacing(2, 3),
    marginTop: "auto",
    background: "white",
    borderTop: `1px solid ${theme.customPalette.greyClear}`,
  },
  buttonCheckout: {
    width: "100%",
  },
  title: {
    lineHeight: 1.29,
  },
  bodyTitle: {
    marginTop: 16,
    marginBottom: theme.spacing(3),
  },
  issuesWrapper: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(3),
  },
  selectLabel: {
    margin: "16px 0px 32px 0px",
    fontSize: "14px!important",
  },
  select: {
    margin: theme.spacing(2, 0, 4, 0),
    fontSize: "14px!important",
  },
  plan: {
    position: "relative",
    width: "100%",
    marginBottom: theme.spacing(3),
    border: "solid 4px #f5f5f5",
    borderRadius: 0,
    cursor: "pointer",
    overflow: "visible",
  },
  planTitle: {
    lineHeight: 1.17,
  },
  planDescription: {
    lineHeight: 1.5,
    padding: "16px",
  },
  selected: {
    borderColor: theme.customPalette.tangerine,
  },
  specialItemsTitle: {
    marginBottom: theme.spacing(2),
    lineHeight: 1.5,
  },
  specialItem: {
    height: "100%",
    maxWidth: 120,
    "& .MuiCardContent-root:last-child": {
      paddingBottom: theme.spacing(1),
    },
  },
  specialItemImage: {
    height: 90,
  },
  specialItemContent: {
    padding: theme.spacing(1),
  },
  slider: {
    marginBottom: theme.spacing(1),
  },
  carouselItem: {
    marginBottom: theme.spacing(1),
  },
  closeButton: {
    height: "fit-content",
    width: "fit-content",
  },
  icon: {
    width: 20,
    height: 20,
  },
  chip: {
    position: "absolute",
    top: -2,
    left: theme.spacing(3),
    transform: "translate(0,-50%)",
  },
  imageWrapper: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    },
    position: "relative",
  },
  planImage: {
    margin: "auto",
  },
  stamp: {
    left: "3%",
    bottom: "4%",
    //maxWidth: 100,
    transform: "translate(0, -50%)",
    "& > div": {
      //minWidth: 80
    },
  },
  notifyMeImg: {
    height: 170,
    marginTop: theme.spacing(3),
    "& img": {
      objectFit: "cover",
      width: "100%",
      height: "100%",
      objectPosition: "center",
    },
  },
  newsletterSubscription: {},
  formWrapper: {
    background: theme.customPalette.greyClear,
    padding: theme.spacing(5, 6),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 2),
      padding: theme.spacing(5, 2),
    },
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      textAlign: "center",
    },
  },
  subtitle: {
    //fontSize: 24,
    marginTop: theme.spacing(2),
    //marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      paddingBottom: theme.spacing(6),
    },
  },
  subscribeForm: {
    marginTop: theme.spacing(2),
  },
  disclamer: {
    border: `0px solid ${theme.customPalette.primaryGrey05}`,
    background: theme.customPalette.primaryGrey05,
    color: "#7d7d7d",
  },
  resultWrapper: {
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    height: 60,
    borderRadius: "4.3px",
    border: "solid 0px #9e78aa",
    width: "100%",
  },
  result: {
    display: "block",
    verticalAlign: "middle",
    textAlign: "center",
    width: "100%",
    color: "black",
  },
  subscribeButton: {
    height: 60,
    width: "100%",
    ...theme.fonts.faces.bodyM,
    ...theme.fonts.weights.medium,
  },
  emailField: {
    width: "100%",
    height: "100%",
  },
  input: {
    ...theme.fonts.faces.titleL,
    "&::placeholder": {
      color: "black",
    },
  },
  outlinedInput: {
    color: "black",
    height: "100%",
    "&$error $notchedOutline": {
      borderColor: `${theme.palette.error.main} !important`,
    },
  },
  focused: {},
  notchedOutline: {},
  error: {},
  successIcon: {
    width: 60,
    height: 60,
  },
  containerOfferType: {
    width: "100%",
    border: "solid 4px #f5f5f5",
    cursor: "pointer",
    overflow: "visible",
    position: "relative",
    borderRadius: "0",
    marginBottom: "24px",
    marginTop: "32px",
  },
  containerOfferTypeSelected: {
    width: "100%",
    border: "solid 4px #f5f5f5",
    cursor: "pointer",
    overflow: "visible",
    position: "relative",
    borderRadius: "0",
    marginBottom: "24px",
    borderColor: "#f29100",
    marginTop: "24px",
  },
  subscriptionType: {
    display: "flex",
    padding: "16px",
    alignItems: "center",
  },
  issuesBox: {
    margin: "16px 0px 32px 0px",
    fontSize: "14px!important",
    margin: theme.spacing(2, 0, 4, 0),
    fontSize: "14px!important",
  },
  containerReceiveFaster: {
    display: "flex",
    cursor: "pointer",
    marginTop: "16px",
    marginBottom: "16px",
    alignItems: "center",
  },
  iconReceiveFaster: {
    marginLeft: "16px",
    height: "16px",
    width: "16px",
  },
  rotateIcon: {
    transform: "rotate(-180deg)",
  },
  textFK: {
    fontSize: "16px",
    fontFamily: "Spezia, 'Roboto', 'Open Sans', Helvetica, Arial, sans-serif",
    fontStyle: "normal",
    color: "#000000",
  },
}))
const Plan = ({ plan, selected, onSelect }) => {
  const classes = useStyles()

  return (
    <Card
      className={clsx(classes.plan, { [classes.selected]: selected })}
      onClick={() => {
        onSelect(plan)
      }}
      elevation={0}
    >
      {plan.chip && (
        <Chip aria-label="recipe" className={classes.chip} label={plan.chip} />
      )}
      <CardHeader
        action={<Radio value={plan.planCode} />}
        title={
          <Text className={classes.planTitle} variant="titleXXL" weight="bold">
            {plan.title}
          </Text>
        }
      />
      {plan.description && plan.image && (
        <Collapse in={selected} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid container>
              <Grid md={6} item>
                {plan.description && (
                  <Text className={classes.planDescription} variant="bodyM">
                    {plan.description}
                  </Text>
                )}
              </Grid>
              <Grid md={6} item>
                {plan.image && (
                  <div className={classes.imageWrapper}>
                    <FluidImage className={classes.planImage} {...plan.image} />
                    {plan.badgeText && (
                      <Stamp className={classes.stamp}>
                        {txt.priceUplift(plan.badgeText, 1.4)}
                      </Stamp>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
      )}
    </Card>
  )
}
const OfferDrawer = ({
  open,
  title,
  bodyTitle,
  issuesSelectLabel,
  buttonText,
  openPlanConf,
  deliveryPlans,
  deliveryPlan,
  offerTypes,
  offerType,
  checkSku,
  selectedIssue,
  onPlanChange,
  onIssueChange,
  onClose,
  onConfirm,
  notifyme,
  productCode,
  offerData,
  others,
  issues,
  issue,
  afterOpenModal,
  closeModal,
  values,
  info,
  setIssue,
  setOpenPlanConf,
  addToCart,
  currencyConf,
}) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(59, 65, 84, .80)",
      transform: "translate(-50%, -50%)",
      border: "none",
      transition: "all .4s ease-out",
    },
    overlay: {
      zIndex: "3000",
    },
    ".overlay--after-open": {
      backgroundColor: "red !important",
    },
  }

  // -- debug
  //console.debug("Inside drawer:")
  //console.log("deliveryPlans", deliveryPlans)
  //console.log("selectedPlanCode", deliveryPlan)
  //console.log('open', open)
  //console.debug("Inside drawer OTHERS:")
  //console.log(others)

  if (
    deliveryPlan &&
    typeof offerData?.offerTypes !== "undefined" &&
    offerData.offerTypes[deliveryPlan][offerType]?.issues &&
    Object.keys(offerData.offerTypes[deliveryPlan][offerType]?.issues).length
  ) {
    console.log(
      "issues...........",
      offerData?.offerTypes[deliveryPlan][offerType].issues
    )
  }

  const classes = useStyles()
  const country = detectAlpha2Country() ? detectAlpha2Country() : "uk"
  const [status, setStatus] = useState(null)
  const [openPlanFK, setOpenPlanFK] = useState(null)
  const { t } = useTranslation()

  const handleResult = res => {
    if (res === "success") {
    } else {
    }
  }

  const onParent = e => {
    setStatus(e)
  }

  return !notifyme ? (
    <Drawer
      open={open}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
      ModalProps={{
        disableAutoFocus: true,
        disableEnforceFocus: true,
      }}
      closeTimeoutMS={300}
      isOpen={open}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Configurator"
    >
      <div
        className={`configurator bg-white ${open ? "configurator--open" : ""}`}
        style={{ height: "85%" }}
      >
        <div className={classes.drawerHeader}>
          <Text className={classes.title} variant="bodyXS" weight="bold">
            {title}
          </Text>
          <IconButton
            className={classes.closeButton}
            edge="start"
            size="small"
            onClick={onClose}
          >
            <Icon className={classes.icon} name="close" />
          </IconButton>
        </div>

        <Box className={classes.drawerContent}>
          {/* [BEGIN] -- DELIVERY PLANS */}
          {deliveryPlans && Object.keys(deliveryPlans).length > 1 && (
            <Text className={classes.selectLabel} variant="body" weight="bold">
              {bodyTitle}
            </Text>
          )}

          {openPlanConf && (
            <div
              className={`${
                openPlanConf ? "open-plan-conf" : "close-plan-conf"
              } mt-2`}
            >
              {deliveryPlans &&
                Object.keys(deliveryPlans)
                  .sort()
                  .map((plan, index) => {
                    if (
                      plan !== "M12" &&
                      plan !== "RE" &&
                      plan !== "FK" &&
                      plan !== "PL"
                    ) {
                      return (
                        <div
                          key={index}
                          className={
                            deliveryPlan === plan
                              ? classes.containerOfferTypeSelected
                              : classes.containerOfferType
                          }
                          onClick={() => checkSku(plan)}
                        >
                          <div
                            className={classes.subscriptionType}
                            style={{ justifyContent: "space-between" }}
                          >
                            <label
                              className={`d-flex flex-direction-column align-items-start ml-2 cursor-pointer `}
                            >
                              <Text
                                className={classes.planTitle}
                                variant="titleXXL"
                                weight="bold"
                              >
                                {deliveryPlans[plan]?.contents?.title}
                              </Text>
                            </label>
                            <Radio
                              id="configurator-delivery-option"
                              name="delivery-option"
                              value={plan}
                              checked={deliveryPlan === plan}
                              onChange={e => {
                                checkSku(e.target.value)
                              }}
                            ></Radio>
                          </div>
                        </div>
                      )
                    }
                  })}
            </div>
          )}
          {/* [END] -- DELIVERY PLANS */}

          {/* [BEGIN] -- ISSUES */}
          {issues && Object.keys(issues).length > 1 && (
            <Box className={classes.issuesWrapper}>
              {/* Start subscription to */}
              <Text
                className={classes.selectLabel}
                variant="body"
                weight="bold"
              >
                {issuesSelectLabel}
              </Text>
              <Select
                className={classes.issuesBox}
                name="fasterSpedition"
                border="grey03"
                value={JSON.stringify(issue)}
                onChange={select => setIssue(JSON.parse(select.target.value))}
                required
              >
                {Object.keys(issues).length > 0 &&
                  Object.keys(issues).map((value, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={JSON.stringify(issues[value])}
                        className={`text-grey04`}
                      >
                        {issues[value]?.contents?.titleConcat}
                      </MenuItem>
                    )
                  })}
              </Select>
              <Divider />
            </Box>
          )}
          {/* [END] -- ISSUES */}

          {/* [BEGIN] -- OFFER TYPES */}
          {offerTypes &&
            deliveryPlan &&
            Object.keys(offerTypes).length > 1 &&
            Object.keys(offerTypes).map((offer, index) => {
              if (offer !== "IN") {
                return (
                  <div
                    onClick={() => checkSku(deliveryPlan, offer)}
                    className={
                      offer === offerType
                        ? classes.containerOfferTypeSelected
                        : classes.containerOfferType
                    }
                    key={index}
                  >
                    {offerTypes[offer]?.contents?.upTitle && (
                      <Chip
                        aria-label="recipe"
                        className={classes.chip}
                        label={offerTypes[offer]?.contents?.upTitle}
                      />
                    )}

                    <div
                      className={`option-radio-plan px-2 pt-2 pt-md-4 mb-2 ${
                        offer === offerType
                          ? "option-radio-plan--active pb-1 pb-md-3"
                          : "pb-2 pb-md-4"
                      }`}
                    >
                      <div
                        className={classes.subscriptionType}
                        style={{ justifyContent: "space-between" }}
                      >
                        <label
                          className={`d-flex flex-direction-column align-items-start ml-2 cursor-pointer `}
                        >
                          <Text
                            className={classes.planTitle}
                            variant="titleXXL"
                            weight="bold"
                          >
                            {offerTypes[offer]?.contents?.title}
                          </Text>
                        </label>
                        <Radio
                          id="configurator-chose-plane"
                          name="chose-plan"
                          value={offer}
                          checked={offer === offerType}
                          onChange={e => {
                            checkSku(deliveryPlan, e.target.value)
                          }}
                        ></Radio>
                      </div>
                      <Collapse
                        in={offerType == "PR" ? "true" : null}
                        timeout="auto"
                        unmountOnExit
                      >
                        <CardContent>
                          <Grid container>
                            <Grid md={6} item>
                              {offerTypes[offer]?.contents?.description && (
                                <Text
                                  className={classes.planDescription}
                                  variant="bodyM"
                                >
                                  {offerTypes[offer]?.contents?.description}
                                </Text>
                              )}
                            </Grid>
                            <Grid md={6} item>
                              {offerTypes[offer]?.contents?.image && (
                                <div className={classes.imageWrapper}>
                                  <FluidImage
                                    className={classes.planImage}
                                    {...offerTypes[offer]?.contents?.image}
                                  />
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Collapse>
                    </div>
                  </div>
                )
              }
            })}
          {/* [END] -- OFFER TYPES */}

          {/* Delivery options */}
          {deliveryPlans &&
            Object.keys(deliveryPlans).length > 1 &&
            Object.keys(deliveryPlans)
              .sort()
              .map((plan, index) => {
                if (plan === "M12" || plan === "RE") {
                  return (
                    <>
                      <div
                        key={index}
                        className={
                          deliveryPlan === plan
                            ? classes.containerOfferTypeSelected
                            : classes.containerOfferType
                        }
                        onClick={() => checkSku(plan)}
                        style={{ marginTop: "32px" }}
                      >
                        {deliveryPlans[plan]?.contents?.upTitle && (
                          <Chip
                            aria-label="recipe"
                            className={classes.chip}
                            label={deliveryPlans[plan]?.contents?.upTitle}
                          />
                        )}
                        <div
                          className={classes.subscriptionType}
                          style={{ justifyContent: "space-between" }}
                        >
                          <label className="d-flex flex-direction-column align-items-start ml-2 cursor-pointer">
                            <Text
                              className={classes.planTitle}
                              variant="titleXXL"
                              weight="bold"
                            >
                              {deliveryPlans[plan]?.contents?.title}
                            </Text>
                          </label>
                          <Radio
                            id="configurator-delivery-option"
                            name="delivery-option"
                            value={plan}
                            checked={deliveryPlan === plan}
                            onChange={e => {
                              checkSku(e.target.value)
                            }}
                          ></Radio>
                        </div>
                        <Text
                          className={classes.planDescription}
                          variant="bodyM"
                        >
                          {deliveryPlans[plan]?.contents?.subtitle}
                        </Text>

                        <div className="d-flex flex-direction-row align-items-end text-right my-auto">
                          {deliveryPlans[plan]?.contents
                            ?.priceStrikethrough && (
                            <Text
                              className={classes.title}
                              variant="bodyXS"
                              weight="bold"
                            >
                              {
                                deliveryPlans[plan]?.contents
                                  ?.priceStrikethrough
                              }
                            </Text>
                          )}

                          <div className="d-flex flex-direction-column align-items-end ml-1">
                            <Text
                              className={classes.title}
                              variant="bodyXS"
                              weight="bold"
                            >
                              {
                                deliveryPlans[plan]?.contents
                                  ?.discountPercentage
                              }
                            </Text>
                            <Text
                              className={classes.title}
                              variant="bodyXS"
                              weight="bold"
                            >
                              {deliveryPlans[plan]?.contents?.price}
                            </Text>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }
              })}

          {/* Accordion deliveryPlans "receive faster" if include FK (fullkit) */}
          {deliveryPlans &&
            Object.keys(deliveryPlans).length > 0 &&
            Object.keys(deliveryPlans).includes("FK") && (
              <div
                className={classes.containerReceiveFaster}
                onClick={() => setOpenPlanFK(!openPlanFK)}
              >
                <Text className={classes.textFK}>
                  {t("configurator_receive_faster")}
                </Text>
                <Icon
                  className={clsx(
                    classes.iconReceiveFaster,
                    openPlanFK && classes.rotateIcon
                  )}
                  name="open_down"
                ></Icon>
              </div>
            )}

          {/* Delivery FK */}
          {openPlanFK &&
            deliveryPlans &&
            Object.keys(deliveryPlans).length > 1 &&
            Object.keys(deliveryPlans)
              .sort()
              .map((plan, index) => {
                if (plan === "FK") {
                  return (
                    <>
                      <div
                        key={index}
                        className={
                          deliveryPlan === plan
                            ? classes.containerOfferTypeSelected
                            : classes.containerOfferType
                        }
                        onClick={() => checkSku(plan)}
                        style={{ marginTop: "32px" }}
                      >
                        {deliveryPlans[plan]?.contents?.upTitle && (
                          <Chip
                            aria-label="recipe"
                            className={classes.chip}
                            label={deliveryPlans[plan]?.contents?.upTitle}
                          />
                        )}
                        <div
                          className={classes.subscriptionType}
                          style={{ justifyContent: "space-between" }}
                        >
                          <label className="d-flex flex-direction-column align-items-start ml-2 cursor-pointer">
                            <Text
                              className={classes.planTitle}
                              variant="titleXXL"
                              weight="bold"
                            >
                              {deliveryPlans[plan]?.contents?.title}
                            </Text>
                          </label>
                          <Radio
                            id="configurator-delivery-option"
                            name="delivery-option"
                            value={plan}
                            checked={deliveryPlan === plan}
                            onChange={e => {
                              checkSku(e.target.value)
                            }}
                          ></Radio>
                        </div>
                        <Text
                          className={classes.planDescription}
                          variant="bodyM"
                        >
                          {deliveryPlans[plan]?.contents?.subtitle}
                        </Text>

                        <div className="d-flex flex-direction-row align-items-end text-right my-auto">
                          {deliveryPlans[plan]?.contents
                            ?.priceStrikethrough && (
                            <Text
                              className={classes.title}
                              variant="bodyXS"
                              weight="bold"
                            >
                              {
                                deliveryPlans[plan]?.contents
                                  ?.priceStrikethrough
                              }
                            </Text>
                          )}

                          <div className="d-flex flex-direction-column align-items-end ml-1">
                            <Text
                              className={classes.title}
                              variant="bodyXS"
                              weight="bold"
                            >
                              {
                                deliveryPlans[plan]?.contents
                                  ?.discountPercentage
                              }
                            </Text>
                            <Text
                              className={classes.title}
                              variant="bodyXS"
                              weight="bold"
                            >
                              {deliveryPlans[plan]?.contents?.price}
                            </Text>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }
              })}
        </Box>
        {/* [BEGIN] -- CTA */}
        <Box className={classes.drawerActions}>
          <Button
            disabled={!issue}
            onClick={e => addToCart(e, currencyConf)}
            className={classes.buttonCheckout}
            next
          >
            {buttonText}
          </Button>
        </Box>
        {/* [END] -- CTA */}
      </div>
    </Drawer>
  ) : (
    <Drawer
      open={open}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
      ModalProps={{
        disableAutoFocus: true,
        disableEnforceFocus: true,
      }}
    >
      <div className={classes.drawerHeader}>
        <Text className={classes.title} variant="bodyXS" weight="bold">
          {status === "success"
            ? t("notifyme.uptitleConfirm")
            : t("notifyme.uptitle")}
        </Text>
        <IconButton
          className={classes.closeButton}
          edge="start"
          size="small"
          onClick={onClose}
        >
          <Icon className={classes.icon} name="close" />
        </IconButton>
      </div>
      <Box px={2}>
        <Box>
          {status === "success" ? (
            <Box mt={2}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Icon className={classes.successIcon} name={"success"}></Icon>
              </Box>
              <Text
                className={classes.bodyTitle}
                variant="bodyXL"
                weight="bold"
              >
                {t("notifyme.confirm")}
              </Text>
            </Box>
          ) : (
            <Box>
              <Text
                className={classes.bodyTitle}
                variant="bodyXL"
                weight="bold"
              >
                {t("notifyme.title")}
              </Text>
              <Text className={classes.planDescription} variant="bodyM">
                {t("notifyme.description", { name: others?.repo?.title })}
              </Text>
            </Box>
          )}

          {offerData?.assets?.productNotifyMeImage && (
            <FluidImage
              className={classes.notifyMeImg}
              {...offerData?.assets?.productNotifyMeImage}
            ></FluidImage>
          )}

          {status !== "success" && (
            <SubscribeFormNotify
              audience="notifyme"
              classes={{ ...classes }}
              textFieldProps={{
                variant: "filled",
              }}
              flags={{
                notifyme: true,
              }}
              labels={{
                button: t("notifyme.sendCta"),
              }}
              onResult={handleResult}
              buttonBelow
              productCode={productCode}
              onParent={onParent}
              others={others}
            ></SubscribeFormNotify>
          )}
        </Box>
      </Box>
      {status === "success" && (
        <Box className={classes.drawerActions}>
          <Button
            className={classes.buttonCheckout}
            next
            onClick={() => {
              window.location.href = `/${country}/`
            }}
          >
            {t("notifyme.goToHome")}
          </Button>
        </Box>
      )}
    </Drawer>
  )
}

export default OfferDrawer
